import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClient, HttpClientModule, HttpErrorResponse} from '@angular/common/http';
// 1. Import the libs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
const firebaseConfig = {
  apiKey: "AIzaSyAvlcZ0Zy6rt0VXCwslZAqIrBYOZU_lhGg",
  authDomain: "computer-generated-gis.firebaseapp.com",
  projectId: "computer-generated-gis",
  storageBucket: "computer-generated-gis.appspot.com",
  messagingSenderId: "1046164531363",
  appId: "1:1046164531363:web:3a810ae832141028233716",
  measurementId: "G-N5KBQ3B4KT"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), HttpClientModule, AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the app is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
